<router>
{
    name: 'Jonas Tools',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col
            p.black--text Tools for working with Jonas exports
    v-row
        v-col(cols='5')
            BillingsTools

</template>
<script>

import BillingsTools from '@/components/projects/billingsTools.vue';
export default {
    meta: {
        role: 'projects'
    },
    components: {
        BillingsTools
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
    }
};
</script>

<style scoped>
</style>
