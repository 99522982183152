<template lang="pug">
.jonasTools
    v-card(light, elevation='10')
        v-card-title Billings Sheet Generator
        v-card-subtitle Accepts a Jonas Billing JS export file
        v-card-text.pb-0
            v-form(v-model='isValid')
                v-row
                    v-col(cols='12')
                        v-file-input(color='brand', outlined, dense, label='Jonas Billings file', accept='.xlsx', truncate-length='50', v-model='selectedFile', :rules='fileRules')
            v-overlay(absolute, :value='busy')
                .text-h6 Please Wait...
                v-progress-linear(indeterminate)
        v-card-actions.pt-0
            v-spacer
            v-btn.white--text(color='brand', :disabled='!isValid || busy', @click='startBillings(selectedFile)') Process

    v-dialog(v-model='dialogError', max-width='350px')
        v-card
            v-card-title Invalid File Format
            v-card-text.pa-0
                v-container
                    v-row
                        v-col(cols='4')
                            v-icon(size='100px', center) description
                        v-spacer
                        v-col(cols='8', align-self='center')
                            p {{ dialogErrorText }}
                        v-spacer
            v-card-actions
                v-spacer
                v-btn.white--text(color='brand', @click='dialogError=false') Okay
</template>

<script>
import _ from 'lodash';
import * as ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';

const colors = {
    tan: 'FFF4B084',
    lightTan: 'FFFCE4D6',
    blue: 'FFB4C6E7',
    lightBlue: 'FFD9E1F2',
    black: 'FF000000',
    green: 'FFC6EFCE',
    yellow: 'FFFFFF00',
    deepRed: 'FFFFC7CE',
};
export default {

    data () {
        return {
            dti,
            accessKey: 'acctjonas',
            busy: false,
            isValid: false,
            selectedFile: null,
            selectedFileName: '',
            dialogError: false,
            dialogErrorText: '',
            fileRules: [
                v => !!v || 'File is required',
                v => v && v.name.includes('.xlsx') || 'Please use a (.xlsx) Jonas Billings export file. '
            ],
            rowTemplateCost: {
                jobNumber: '',
                costItem: 0,
                description: '',
                estimateCost: 0,
                estimateHours: 0,
                costType: '',
                monthHours: 0,
                monthCost: 0,
                totalHours: 0,
                totalCost: 0,
                totalCostLastEOM: 0
            },
            newWorkbook: null,
            newWorksheet: null,
            dataRowOffset: 2, //the number of rows to skip before we start adding data rows
            combineStart: 5000,
            combineEnd: 5999,
            combineExclude: [5010],
            
            //Cell Colors


            //Number formats
            formatCurrency: '$#,##0.00' ,
            formatPercent: '0.00%',

            borderTopBottomThin: {
                top: {style:'thin'},
                bottom: {style:'thin'},
            },
            borderBottomThin: {
                bottom: {style: 'thin'}
            },

            totalCostPrevEomRule: {
                priority: 1,
                type: 'expression',
                formulae: ['F2>E2'],
                style: {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: colors.deepRed },
                    }
                }
            },

            totalCostPrevEomPercentRule: {
                priority: 1,
                type: 'expression',
                formulae: ['G2>1'],
                style: {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: colors.deepRed },
                    }
                }
            },

            monthCostRule: {
                priority: 1,
                type: 'expression',
                formulae: ['I2>1'],
                style: {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: colors.green },
                    }
                }
            },

            completeRule: {
                priority: 1,
                type: 'expression',
                formulae: ['J2 > 100%'],
                style: {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: colors.deepRed },
                    }
                }
            },

            ttdCostRule: {
                priority: 1,
                type: 'expression',
                formulae: ['L2 > E2'],
                style: {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: colors.deepRed },
                    }
                }
            },

            completeCalculatedRule: {
                priority: 1,
                type: 'expression',
                formulae: ['R2 > 100%'],
                style: {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: colors.deepRed },
                    }
                }
            },

            hoursRemainingRule: {
                priority: 1,
                type: 'expression',
                formulae: ['S2 < 0'],
                style: {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: colors.deepRed },
                    }
                }
            },

            hoursPrevEomRule: {
                priority: 1,
                type: 'expression',
                formulae: ['P2 > O2'],
                style: {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: colors.deepRed },
                    }
                }
            },

            currentHoursRule: {
                priority: 1,
                type: 'expression',
                formulae: ['Q2 > O2'],
                style: {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: colors.deepRed },
                    }
                }
            },

            remainingHoursRule: {
                priority: 1,
                type: 'expression',
                formulae: ['S2 < 0'],
                style: {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: colors.deepRed },
                    }
                }
            },
        };
    },
    methods: {
        async startBillings (fileRaw) {
            this.busy = true;

            //Check file format
            if(fileRaw.name.includes('.xlsx') === false) {
                this.dialogErrorText = 'Invalid file format. Please use a Jonas Billings export file. (.xlsx)';
                this.dialogError = true;
                this.busy = false;
                return;
            }

            try {
                this.selectedFileName = fileRaw.name.replace('.xlsx', '');
                const workbook = await this.getWorkbook(fileRaw);
                const worksheet = workbook.worksheets[0];
                const rows = worksheet.getSheetValues();
                const dataRows = await this.getDataRows(rows);

                if(dataRows.length === 0) {
                    this.dialogErrorText = 'No valid data rows found in file. Please check the Jonas export file and try again.';
                    this.dialogError = true;
                    this.busy = false;
                    return;
                }
                
                this.newWorkbook = this.createNewWorkbook();
                this.newWorksheet = this.newWorkbook.addWorksheet('Billings');
                this.newWorksheet.properties.defaultColWidth = 15;
                
                const tableRows = await this.insertBillingRows(dataRows);
                
                // Write to file
                const buffer = await this.newWorkbook.xlsx.writeBuffer();
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const fileName = `billings-${this.selectedFileName}.xlsx`;
                const fileBlob = new Blob([buffer], {type: fileType});
                saveAs(fileBlob, fileName);

            } catch (e) {
                console.log(e.message);
                this.dialogErrorText = `An error occcurred when processing this file.<br><br>Error: ${e.message}`;
                this.dialogError = true;
            } finally {
                this.selectedFileName = '';
                this.busy = false;
            }
        },

        async getWorkbook (fileRaw) {
            try {
                const fileData = await this.getFileBuffer(fileRaw);
    
                const workbook = new ExcelJS.Workbook();
                return workbook.xlsx.load(fileData);

            } catch (e) {
                this.$store.dispatch('snackbar/show', {
                    color: 'error',
                    text: e.message
                });
                return null;
            }
        },

        async getFileBuffer (fileRaw) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    try {
                        const arrayBuffer = e.target.result;
                        resolve(arrayBuffer);
                    } catch (err) {
                        reject(err);
                    }
                };
                reader.readAsBinaryString(fileRaw);
            });
        },

        async getDataRows (allRows) {
            let data = [];
            let combineRows = [];
            
            

            allRows.forEach((row) => {
                const jobNumber = row[1];
                const costItemDesc = row[2];
                const monthHours = row[4];

                //Checking for a number column should narrrow down our search like 90%
                if(typeof(monthHours) === 'number') {
                    //check if jobnumber is string
                    if(typeof(jobNumber) === 'string' && typeof(costItemDesc) === 'string') {
                        //The cost item/description row has a 4 digit cost code at the beginning.
                        //If the string is less than 4, there's no code for sure.
                        if(costItemDesc.length > 4) {
                            try {
                                //Final check is to see if cost item can be pulled out of cost item/description column
                                let costItem = parseInt(costItemDesc.substring(0, 4));
                                if (isNaN(costItem)) return;

                                let rowTemplate = _.cloneDeep(this.rowTemplateCost);
                                rowTemplate.jobNumber = jobNumber;
                                rowTemplate.costItem = costItem;
                                rowTemplate.description = costItemDesc.substring(4);
                                rowTemplate.costType = row[3];
                                rowTemplate.monthHours = monthHours;
                                rowTemplate.monthCost = row[5];
                                rowTemplate.totalHours = row[6];
                                rowTemplate.totalCost = row[7];
                                rowTemplate.estimateCost = row[9];
                                rowTemplate.estimateHours = row[10];

                                //Check if this row is a combined row
                                if(costItem >= this.combineStart && costItem <= this.combineEnd) {
                                    if(this.combineExclude.includes(costItem)) {
                                        data.push(rowTemplate);
                                    }
                                    else {
                                        combineRows.push(rowTemplate);
                                    }
                                } else {
                                    data.push(rowTemplate);
                                }

                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                }
            });

            
            const combinedRows = await this.combineRows(combineRows);
            if(combinedRows.length) {
                data.push(...combinedRows);
            }

            const projectTotalRows = await this.getProjectTotalRows(data);
            if(projectTotalRows.length) {
                data.push(...projectTotalRows);
            }
            
            if(data.length > 1) {
                data.sort((a, b) => {
                    // Compare based on 'jobNumber' first
                    const jobNumberComparison = a.jobNumber.localeCompare(b.jobNumber, undefined, { numeric: true, sensitivity: 'base' });
                    // If 'jobNumber' is the same, compare based on 'costItem'
                    if (jobNumberComparison === 0) {
                        return a.costItem - b.costItem;
                    }

                    return jobNumberComparison;
                });
            }

            return data;
        },

        async getProjectTotalRows (allRows) {
            let jobNumbers = [...new Set(allRows.map(x => x.jobNumber))];
            let projectTotalRows = [];

            jobNumbers.forEach(jobNumber => {
                let projectTotalRow = _.cloneDeep(this.rowTemplateCost);
                projectTotalRow.jobNumber = jobNumber;
                projectTotalRow.costItem = undefined;
                projectTotalRow.description = 'Project Totals';
                
                let jobRows = allRows.filter(x => x.jobNumber === jobNumber);
                jobRows.forEach(row => {
                    projectTotalRow.monthHours += row.monthHours;
                    projectTotalRow.monthCost += row.monthCost;
                    projectTotalRow.totalHours += row.totalHours;
                    projectTotalRow.totalCost += row.totalCost;
                    projectTotalRow.estimateCost += row.estimateCost;
                    projectTotalRow.estimateHours += row.estimateHours;
                });

                projectTotalRows.push(projectTotalRow);
            });

            return projectTotalRows;
        },

        async combineRows (allRows) {
            //Get unique job numbers
            let combinedRows = [];
            let jobNumbers = [...new Set(allRows.map(x => x.jobNumber))];

            jobNumbers.forEach(job => {
                let combinedRow = _.cloneDeep(this.rowTemplateCost);
                combinedRow.jobNumber = job;
                combinedRow.costItem = this.combineStart;
                combinedRow.description = 'Purchased Goods';
                
                let rows = allRows.filter(x => x.jobNumber === job);
                rows.forEach(row => {
                    combinedRow.monthHours += row.monthHours;
                    combinedRow.monthCost += row.monthCost;
                    combinedRow.totalHours += row.totalHours;
                    combinedRow.totalCost += row.totalCost;
                    combinedRow.estimateCost += row.estimateCost;
                    combinedRow.estimateHours += row.estimateHours;
                });

                combinedRows.push(combinedRow);
            });

            return combinedRows;
        },

        createNewWorkbook () {
            const workbook = new ExcelJS.Workbook();
            workbook.creator = 'Dorsett Controls';
            workbook.lastModifiedBy = 'Dorsett Controls';
            workbook.created = new Date();
            workbook.modified = new Date();
            workbook.lastPrinted = new Date();

            workbook.calcProperties.fullCalcOnLoad = true;

            workbook.views = [{
                x: 0, y: 0, width: 10000, height: 20000,
                firstSheet: 0, activeTab: 1, visibility: 'visible'
            }];



            return workbook;
        },

        async insertBillingRows (dataRows) {
            let rowCount = dataRows.length;
            let rows = [];
            
            // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            //          1                2            3                4                5              6                  7                    8                   9                    10          
            // Project Information | COST ITEM | Description | Original Budget | Revised Budget | Total Cost | Calculated % Complete | Total Current Cost | Cost this Month | Calculated % Complete 
            // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            //             11                    12                      13                  14                  15                  16               17                  18                  19                  20
            // Additional Costs to EOM | Total To-Date Costs | Projected % Complete | Balance of Budget | Estimated Hours | Hours @ Previous | Current Hours | Calculated % Complete | Remaining Hours | Enter any additional
            // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            
            //Create header row in new worksheet
            let columns = [
                { name: 'Project',},
                { name: 'Cost Item'},
                { name: 'Description'},
                { name: 'Original Budget'},
                { name: 'Revised Budget'},
                { name: 'Total Cost - Previous EOM'},
                { name: '% Complete - Previous EOM'},
                { name: 'Total Current Cost'},
                { name: 'Cost This Month'},
                { name: '% Complete'},
                { name: 'Additional Costs'},
                { name: 'Total To Date Costs'},
                { name: 'Projected % Complete'},
                { name: 'Balance of Budget'},
                { name: 'Estimated Hours'},
                { name: 'Hours - Previous EOM'},
                { name: 'Current Hours'},
                { name: 'Calculated % Complete'},
                { name: 'Remaining Hours'},
                { name: 'Any Additional Hours to Budget'},
            ];

            //Set shared column properties
            columns.forEach((col, index) => {
                col.filterButton = true;
                col.style = {
                    font: {
                        name: 'Tahoma',
                        size: 11,
                    }
                };
            });

            //Add the data rows
            dataRows.forEach((obj, i) => {
                const rowNumber = i + this.dataRowOffset;
                let row = [];
                row.push(obj.jobNumber);
                row.push(obj.costItem);
                row.push(obj.description);
                row.push(0);
                row.push(obj.estimateCost);
                row.push(obj.totalCost - obj.monthCost);
                row.push({ formula: `IFERROR(F${rowNumber}/E${rowNumber},0)`, result:0 });
                row.push(obj.totalCost);
                row.push({ formula: `H${rowNumber}-F${rowNumber}`, result:0 });
                row.push({ formula: `IFERROR(H${rowNumber}/E${rowNumber},0)`, result:0 });
                row.push(0);
                row.push({ formula: `H${rowNumber}+K${rowNumber}`, result:0 });
                row.push({ formula: `IFERROR(L${rowNumber}/E${rowNumber},0)`, result:0 });
                row.push({ formula: `E${rowNumber}-L${rowNumber}`, result:0 });
                row.push(obj.estimateHours);
                row.push(obj.totalHours - obj.monthHours);
                row.push(obj.totalHours);
                row.push({ formula: `IFERROR(Q${rowNumber}/O${rowNumber},0)`, result:0 });
                row.push({ formula: `O${rowNumber}-Q${rowNumber}`, result:0 });
                row.push('');

                rows.push(row);
            });

            let billingTable = this.newWorksheet.addTable({
                name: "BillingsTable",
                ref: 'A1',
                headerRow: true,
                columns,
                rows,
            });
            
            //Format rows
            this.formatRows();
            

            // apply conditional formatting rules
            this.newWorksheet.conditionalFormattings = [
                { ref: 'F2:F' + rowCount, rules: [this.totalCostPrevEomRule] },
                { ref: 'G2:G' + rowCount, rules: [this.totalCostPrevEomPercentRule] },
                { ref: 'I2:I' + rowCount, rules: [this.monthCostRule] },
                { ref: 'J2:J' + rowCount, rules: [this.completeRule] },
                { ref: 'L2:L' + rowCount, rules: [this.ttdCostRule] },
                { ref: 'R2:R' + rowCount, rules: [this.completeCalculatedRule] },
                { ref: 'S2:S' + rowCount, rules: [this.hoursRemainingRule] },
                { ref: 'P2:P' + rowCount, rules: [this.hoursPrevEomRule] },
                { ref: 'Q2:Q' + rowCount, rules: [this.currentHoursRule] },
                { ref: 'S2:S' + rowCount, rules: [this.remainingHoursRule] },
            ];

            this.formatColumns();
        },
        
        formatRows () {
            //Shift is used to remove the header row from formatting here
            let rows = this.newWorksheet._rows;

            rows.forEach((row, index) => {
                //All but header row
                if(index != 0) {
                    let cells = row._cells;
        
                    cells.forEach((cell, index) => {
                        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: {argb: this.billingsTheme.rowColors[index]} };
                        cell.border = this.borderTopBottomThin;

                    });
        
                    //Set the font of the row because this package doesn't support default styles :(
                    row.font = { name: 'Tahoma', size: 11, bold: false, italic: false, underline: false, color: { argb: this.black } };
                }
                else {
                    this.formatHeaderRow();
                }
            });
        },

        formatHeaderRow () {
            let headerRow = this.newWorksheet.getRow(1);
        
            headerRow.font = { name: 'Tahoma', size: 11, bold: true, italic: false, underline: false, color: { argb: 'FF000000' } };
            headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            headerRow.height = 40;
            headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: this.blue } };

            headerRow._cells.forEach((cell, index) => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: {argb: this.billingsTheme.headerColors[index]} };
                cell.border = this.borderBottomThin;
            });
        },

        formatColumns () {
            this.newWorksheet.columns.forEach((column, index) => {
                column.width = this.billingsTheme.rowWidths[index];

                if(this.billingsTheme.rowNumberFormats[index]) {
                    column.numFmt = this.billingsTheme.rowNumberFormats[index];
                }
            });
        },

        getCellName (row, col) {
            return String.fromCharCode(65 + col) + (row + 1);
        },

        

    },

    computed: {
        billingsTheme () {
            let rowColors = [
                colors.lightBlue,
                colors.lightBlue,
                colors.lightBlue,
                colors.lightBlue,
                colors.lightBlue,
                colors.lightBlue,
                colors.lightTan,
                colors.lightBlue,
                colors.lightTan,
                colors.lightTan,
                colors.yellow,
                colors.lightTan,
                colors.lightTan,
                colors.lightTan,
                colors.lightBlue,
                colors.lightBlue,
                colors.lightBlue,
                colors.lightTan,
                colors.lightBlue,
                colors.yellow
            ];

            let rowWidths = [
                15,
                10,
                40,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                10,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                40
            ];

            let rowNumberFormats = [
                null,
                null,
                null,
                this.formatCurrency,
                this.formatCurrency,
                this.formatCurrency,
                this.formatPercent,
                this.formatCurrency,
                this.formatCurrency,
                this.formatPercent,
                this.formatCurrency,
                this.formatCurrency,
                this.formatPercent,
                this.formatCurrency,
                null,
                null,
                null,
                this.formatPercent,
                null,
                null
            ];

            let headerColors = [
                colors.blue,
                colors.blue,
                colors.blue,
                colors.blue,
                colors.blue,
                colors.blue,
                colors.tan,
                colors.blue,
                colors.tan,
                colors.tan,
                colors.yellow,
                colors.tan,
                colors.tan,
                colors.tan,
                colors.blue,
                colors.blue,
                colors.blue,
                colors.tan,
                colors.blue,
                colors.yellow
            ];

            return {rowColors, rowWidths, rowNumberFormats, headerColors};
        }
        
    },

    watch: {
    },
    mounted () {
        // get all permissions
        window.jonasTools = this;
    }
};
</script>
